<template>
  <v-row no-gutters justify="center" align="center" style="height: 100vh;">
    <v-card v-if="validToken && !errMsg" flat>
      <v-icon size="100" class="mdi-spin" color="primary">mdi-loading</v-icon>
    </v-card>
    <v-card v-else-if="!validToken && errMsg" flat>
      <div class="text-center">
        <h4>{{ errMsg }}<br /></h4>
        <v-icon size="20" class="mdi-spin mt-2" color="primary"
          >mdi-loading</v-icon
        >
      </div>
    </v-card>
    <template v-else>
      <v-card
        v-if="!complete"
        style="position: relative"
        class="text-center elevation-2"
        width="300"
      >
        <div class="icon-float">
          <v-row no-gutters justify="center" align="center">
            <v-img style="width: 40px" src="@/assets/svg/sistesi.svg" />
          </v-row>
        </div>
        <v-form
          ref="formNewPassword"
          lazy-validation
          @submit.prevent="submitForgotPassword"
        >
          <v-card-text class="pt-4">
            <div class="mb-3 mt-10 font-weight-bold">
              {{ $t("login.update_password_title") }}
            </div>

            <v-text-field
              v-model="new_password"
              :label="$t('app.password_new')"
              :append-icon="spNew ? 'mdi-eye' : 'mdi-eye-off'"
              :type="spNew ? 'text' : 'password'"
              :rules="[v => !!v || $t('app.required')]"
              prepend-inner-icon="mdi-key-variant"
              autocomplete
              outlined
              dense
              @click:append="spNew = !spNew"
            />
            <v-text-field
              v-model="confirm_password"
              :label="$t('app.password_confirm')"
              :append-icon="spConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="spConfirm ? 'text' : 'password'"
              :rules="[
                v => !!v || $t('app.required'),
                v => v == new_password || $t('app.password_confirm_rules')
              ]"
              prepend-inner-icon="mdi-key-variant"
              autocomplete
              outlined
              dense
              @click:append="spConfirm = !spConfirm"
            />

            <v-row no-gutters>
              <v-btn
                :loading="loadingNewPassword"
                type="submit"
                color="primary"
                depressed
                block
              >
                {{ $t("login.save_password") }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
      <v-card
        v-if="complete"
        style="position: relative"
        class="text-center elevation-2"
        width="300"
      >
        <div
          class="icon-float-success white rounded-pill elevation-2 text-center"
        >
          <v-img
            style="width: 65px"
            class="mx-auto ml-4 mt-3"
            src="@/assets/svg/update_password.svg"
          />
        </div>
        <v-card-text>
          <h2 class="mb-3 mt-10 font-weight-bold">
            {{ $t("login.update_password_title_success") }}
          </h2>
          <div class="font-weight-normal">
            {{ $t("login.update_password_text_success") }}
          </div>
          <v-icon size="40" class="mdi-spin mt-2" color="primary"
            >mdi-loading</v-icon
          >
        </v-card-text>
      </v-card>
    </template>
  </v-row>
</template>

<script>
import { user_update_password, validate_update_password } from "@/api/login";
export default {
  data() {
    return {
      validToken: false,
      new_password: "",
      confirm_password: "",
      spNew: false,
      spConfirm: false,
      loadingNewPassword: false,
      complete: false,
      errMsg: ""
    };
  },
  created() {
    this.validToken = true;
    validate_update_password({ token: this.$route.params.token }).then(
      async res => {
        if (!res.data.code) {
          this.errMsg = res.data.message;
          setTimeout(() => {
            this.$router.push("/login");
          }, 3000);
        }
        this.validToken = false;
      }
    );
  },
  methods: {
    submitForgotPassword() {
      if (this.$refs.formNewPassword.validate()) {
        this.loadingNewPassword = true;
        user_update_password({
          new_password_confirm: this.confirm_password,
          new_password: this.new_password,
          token: this.$route.params.token
        })
          .then(res => {
            if (res.data.code) {
              this.email = "";
              this.complete = true;
              setTimeout(() => {
                this.$router.push("/login");
              }, 4000);
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingNewPassword = false;
          })
          .catch(() => (this.loadingNewPassword = false));
      }
    }
  }
};
</script>

<style lang="scss">
.icon-float {
  position: absolute;
  top: -50px;
  left: 38%;
  z-index: 100;
  width: 75px;
  height: 75px;
}
.icon-float-success {
  position: absolute;
  top: -50px;
  left: 38%;
  z-index: 100;
  width: 90px;
  height: 90px;
}
</style>
